import actionTypes from '../actionTypes';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_NOTIFICATION: {
      const { type, message } = action.payload;

      if (type) {
        let notification = false;
        const validTypes = ['system', 'error', 'warning', 'success', 'forbidden'];
        if (validTypes.indexOf(type) !== -1) {
          notification = {
            type,
            msg: message,
          };
        }

        return [
          ...state,
          notification,
        ];
      }

      return state;
    }

    case actionTypes.DELETE_NOTIFICATION: {
      const { index } = action.payload;
      let notifications = [];

      if (state) {
        notifications = state.filter((notification, i) => {
          if (i === index) {
            return false;
          }

          return true;
        });
      }

      return notifications;
    }

    default: {
      return state;
    }
  }
};
