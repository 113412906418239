import React, { Suspense } from 'react';
import {
  BrowserRouter as Router, Route, Switch, Redirect,
} from 'react-router-dom';
import Loading from '../components/Loading';

const AuthLogin = React.lazy(() => import('../pages/Auth/Login'));
const AuthRegister = React.lazy(() => import('../pages/Auth/Register'));
const AuthRecover = React.lazy(() => import('../pages/Auth/Recover'));
const AuthReset = React.lazy(() => import('../pages/Auth/Reset'));
const AuthVerify = React.lazy(() => import('../pages/Auth/Verify'));

const Page404 = React.lazy(() => import('../pages/Page404'));

const NotAuthenticated = () => (

  <Router>
    <Suspense fallback={<Loading />}>
      <Switch>
        <Redirect from="/" to="/login" exact />

        <Route exact path="/login" component={(props) => <AuthLogin {...props} />} />
        <Route exact path="/register" component={(props) => <AuthRegister {...props} />} />
        <Route exact path="/recover" component={(props) => <AuthRecover {...props} />} />
        <Route exact path="/reset/:token" component={(props) => <AuthReset {...props} />} />
        <Route exact path="/verify/:token" component={(props) => <AuthVerify {...props} />} />

        <Route exact path="*" component={() => <Page404 />} />
      </Switch>
    </Suspense>
  </Router>

);

export default NotAuthenticated;
