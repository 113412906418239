import React, { Suspense } from 'react';
import {
  BrowserRouter as Router, Route, Switch,
} from 'react-router-dom';
import Loading from '../components/Loading';

const LayoutContent = React.lazy(() => import('../components/Layout/Content'));

const Authenticated = () => (

  <Router>
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path="/" render={(props) => <LayoutContent {...props} />} />
      </Switch>
    </Suspense>
  </Router>

);

export default Authenticated;
