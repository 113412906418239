import React from 'react';
import { connect } from 'react-redux';
import './App.scss';
import PropTypes from 'prop-types';

import Authenticated from './routes/Authenticated';
import NotAuthenticated from './routes/NotAuthenticated';

const App = (props) => {
  const { isLoggedIn } = props;

  return (
    isLoggedIn ? <Authenticated /> : <NotAuthenticated />
  );
};

App.defaultProps = {
  isLoggedIn: false,
};

App.propTypes = {
  isLoggedIn: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.session.isLoggedIn,
});

export default connect(mapStateToProps)(App);
