import actionTypes from '../actionTypes';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_USER: {
      return {
        ...state,
        ...action.payload.user,
      };
    }

    default: {
      return state;
    }
  }
};
