import actionTypes from '../actionTypes';

const initialState = {
  isLoggedIn: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_SESSION: {
      localStorage.setItem('token', action.payload.token);

      return {
        ...state,
        isLoggedIn: !!action.payload.token,
      };
    }

    default: {
      return state;
    }
  }
};
