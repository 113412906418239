import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

const Loading = ({ t }) => (

  <div className="loadig">
    <div className="spinner-border text-info m-5" role="status">
      <span className="sr-only">{t('loading')}</span>
    </div>
  </div>

);

Loading.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Loading);
