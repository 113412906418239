import { combineReducers } from 'redux';

import session from './session';
import user from './user';
import notifications from './notifications';

const rootReducer = combineReducers({
  session,
  user,
  notifications,
});

export default rootReducer;
