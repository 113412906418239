import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import pt from './translations/pt';
import en from './translations/en';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      pt: {
        translation: pt,
      },
      en: {
        translation: en,
      },
    },
    lng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
